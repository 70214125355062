import { PageContext, FormContext } from '../pageContext';
import moment from 'moment';
import { Cascader, DatePicker, Switch, Upload } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useContext, useEffect, useState } from "react";
import { getBaseUrl } from "../utils";

function Step2() {
    const [step, setStep] = useContext(PageContext);
    const [form, setFrom] = useContext(FormContext);
    const [breeds, setBreeds] = useState([{id: 0, label: "None"}]);
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState("");

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
      };
    if(form.reg === undefined) {
        setFrom({ ...form, reg: {} });
    }
    
    const saveForm = (key, value) => {
        let obj = {};
        obj[key] = value;
        if(form.reg !== undefined) {
            obj = { ...form.reg, ...obj };
        }
        setFrom({ ...form, reg: obj });
    }
    
    const saveForms = (obj) => {
        if(form.reg !== undefined) {
            obj = { ...form.reg, ...obj };
        }
        setFrom({ ...form, reg: obj });
    }

    useEffect(() => {
      fetch(`${getBaseUrl()}/api/web/species`)
        .then(response => response.json())
        .then((data) => {
            setBreeds(data.data.map(el => {
                return {
                    value: el.id,
                    label: el.name,
                    children: el.breeds.map(e => {
                        return {
                            value: e.id,
                            label: e.name,
                        };
                    })
                };
            }))
        })
    }, []);

    const genders = [
        {
            value: 'male',
            label: '男'
        },
        {
            value: 'female',
            label: '女'
        },
    ];

    const body_conditions = [
        {
            value: '健康',
            label: '健康'
        },
        {
            value: '視力不足',
            label: '視力不足'
        },
        {
            value: '行動不便',
            label: '行動不便'
        },
        {
            value: '需定期覆診',
            label: '需定期覆診'
        },
        {
            value: '需長期服藥',
            label: '需長期服藥'
        },
        {
            value: '長期病患',
            label: '長期病患'
        },

    ]

    const handleChange = (info) => {
        console.log(info);
        if (info.file.status === 'uploading') {
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                saveForm('pet_birth_paper', url);
            });
            return;
        }
    };

    const handleChangepet_dog_licence_image = (info) => {
        console.log(info);
        if (info.file.status === 'uploading') {
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                saveForm('pet_dog_licence_image', url);
            });
            return;
        }
    };

    const handleChangepet_vaccination_needle_image = (info) => {
        console.log(info);
        if (info.file.status === 'uploading') {
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                saveForm('pet_vaccination_needle_image', url);
            });
            return;
        }
    };
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <div>
            <header>
                <div className={"container"}>
                    <div className={"banner"}><img src="images/petclub.jpg" /></div>
                </div>
            </header>
            <div className={"content"}>
                <div className={"container"}>
                    <form className={"form"}>
                        <h2><span>註冊</span></h2>
                        <div className={"form-group"}>
                            <div className={"col-6"}>
                                <div className={"label_a"}>寵物姓名</div>
                                <input
                                    className={"form-control"}
                                    type="text"
                                    placeholder="請輸入寵物姓名"
                                    value={(form.reg || {}).pet_name || ''}
                                    onChange={(e) => saveForm('pet_name', e.target.value)}
                                />
                            </div>
                            <div className={"col-6"}>
                                <div className={"label_a"}>寵物出生日期</div>
                                <DatePicker
                                    style={{
                                        width: "100% !important",
                                        padding: "12px 0 !important"
                                    }}
                                    className={"form-control"}
                                    placeholder="寵物出生日期"
                                    value={(form.reg || {}).pet_birthday ? (moment(form.reg.pet_birthday)) : ''}
                                    onChange={(item)=> {
                                        saveForm('pet_birthday', item.format("YYYY-MM-DD") || '')
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"form-group"}>
                            <div className={"col-6"}>
                                <div className={"label_a"}>寵物性別</div>
                                <Cascader
                                    style={{
                                        width: "100%",
                                        padding: "7px 0"
                                    }}
                                    value={[(genders.find(el => el.value == ((form.reg || {}).pet_gender || 0)) || {}).label || '']}
                                    bordered={false}
                                    className={"form-control"}
                                    placeholder="寵物性別"
                                    options={genders}
                                    onChange={(item)=> {
                                        saveForm('pet_gender', item[0] || 0)
                                    }}
                                />
                            </div>
                            <div className={"col-6"}>
                                <div className={"label_a"}>寵物種類</div>
                                <Cascader
                                    style={{
                                        width: "100%",
                                        padding: "7px 0"
                                    }}
                                    value={
                                        breeds.find(el => el.value == ((form.reg || {}).pet_specie || 0)) ? (
                                            breeds.find(el => el.value == ((form.reg || {}).pet_specie || 0)).children.find(el => el.value == ((form.reg || {}).pet_breed || 0)) ?
                                            [breeds.find(el => el.value == ((form.reg || {}).pet_specie || 0)).label, breeds.find(el => el.value == ((form.reg || {}).pet_specie || 0)).children.find(el => el.value == ((form.reg || {}).pet_breed || 0)).label] :
                                            [breeds.find(el => el.value == ((form.reg || {}).pet_specie || 0)).label]
                                        ) : []}
                                    bordered={false}
                                    className={"form-control"}
                                    placeholder="寵物種類"
                                    options={breeds}
                                    onChange={(item)=> {
                                        saveForms({
                                            pet_specie: item ? item[0] || 0 : 0,
                                            pet_breed: item ? item[1] || 0 : 0
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"form-group"}>
                            <div className={"col-6"}>
                                <div className={"label_a"}>針卡號碼</div>
                                <input
                                    className={"form-control"}
                                    type="text"
                                    placeholder="請輸入針卡號碼"
                                    value={(form.reg || {}).pet_vaccination_needle_number || ''}
                                    onChange={(e) => saveForm('pet_vaccination_needle_number', e.target.value)}
                                />
                            </div>
                            <div className={"col-6"}>
                                <div className={"label_a"}>防疫針紀錄</div>
                                <DatePicker
                                    style={{
                                        width: "100% !important",
                                        padding: "12px 0 !important"
                                    }}
                                    className={"form-control"}
                                    placeholder="防疫針紀錄"
                                    value={(form.reg || {}).pet_vaccination_record_date ? (moment(form.reg.pet_vaccination_record_date)) : ''}
                                    onChange={(item)=> {
                                        saveForm('pet_vaccination_record_date', item.format("YYYY-MM-DD") || '')
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"form-group"}>
                            <div className={"col-6"}>
                                <div className={"label_a"}>狗牌紀錄編號</div>
                                <input
                                    className={"form-control"}
                                    type="text"
                                    placeholder="請輸入狗牌紀錄編號"
                                    value={(form.reg || {}).pet_dog_licence_number || ''}
                                    onChange={(e) => saveForm('pet_dog_licence_number', e.target.value)}
                                />
                            </div>
                            <div className={"col-6"}>
                                <div className={"label_a"}>狗牌紀錄到期日</div>
                                <DatePicker
                                    style={{
                                        width: "100% !important",
                                        padding: "12px 0 !important"
                                    }}
                                    className={"form-control"}
                                    placeholder="請輸入狗牌紀錄到期日"
                                    value={(form.reg || {}).pet_dog_licence_exp_date ? (moment(form.reg.pet_dog_licence_exp_date)) : ''}
                                    onChange={(item)=> {
                                        saveForm('pet_dog_licence_exp_date', item.format("YYYY-MM-DD") || '')
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"form-group"}>
                            <div className={"col-6"}>
                                <div className={"label_a"}>狗牌紀錄</div>

                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    onChange={handleChangepet_dog_licence_image}
                                >
                                    {((form.reg || {}).pet_dog_licence_image || '') ? <img src={form.reg.pet_dog_licence_image} alt="pet_dog_licence_image" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                            </div>
                            <div className={"col-6"}>
                                <div className={"label_a"}>針卡</div>

                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    onChange={handleChangepet_vaccination_needle_image}
                                >
                                    {((form.reg || {}).pet_vaccination_needle_image || '') ? <img src={form.reg.pet_vaccination_needle_image} alt="pet_vaccination_needle_image" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                            </div>
                        </div>
                        <div className={"form-group"}>
                            <div className={"col-6"}>
                                <div className={"label_a"}>已絕育</div>
                                <Switch checked={((form.reg || {}).pet_sterilized || '') == '1'} onChange={(checked) => saveForm('pet_sterilized', checked ? '1' : '0')} />
                            </div>
                            <div className={"col-6"}>
                                <div className={"label_a"}>出世紙</div>

                                <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    onChange={handleChange}
                                >
                                    {((form.reg || {}).pet_birth_paper || '') ? <img src={form.reg.pet_birth_paper} alt="pet_birth_paper" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>
                            </div>
                        </div>
                        <div className={"form-group"}>
                            <div className={"col-6"}>
                                <div className={"label_a"}>需服用處方糧</div>
                                <Switch checked={((form.reg || {}).pet_prescription_food || '') == '1'} onChange={(checked) => saveForm('pet_prescription_food', checked ? '1' : '0')} />
                            </div>
                            <div className={"col-6"}>
                                <div className={"label_a"}>需服用處方糧 註明</div>
                                <input
                                    className={"form-control"}
                                    type="text"
                                    placeholder="請輸入需服用處方糧 註明"
                                    value={(form.reg || {}).pet_prescription_food_remark || ''}
                                    onChange={(e) => saveForm('pet_prescription_food_remark', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={"form-group"}>
                            <div className={"col-6"}>
                                <div className={"label_a"}>身體狀況</div>
                                <Cascader
                                    style={{
                                        width: "100%",
                                        padding: "7px 0"
                                    }}
                                    value={(form.reg || {}).pet_body_condition}
                                    bordered={false}
                                    className={"form-control"}
                                    placeholder="身體狀況"
                                    multiple={true}
                                    options={body_conditions}
                                    onChange={(item)=> {
                                        console.log(item);
                                        saveForm('pet_body_condition', item)
                                    }}
                                />
                            </div>
                            <div className={"col-6"}>
                                <div className={"label_a"}>身體狀況 註明</div>
                                <input
                                    className={"form-control"}
                                    type="text"
                                    placeholder="請輸入需身體狀況 註明"
                                    value={(form.reg || {}).pet_body_condition_remark || ''}
                                    onChange={(e) => saveForm('pet_body_condition_remark', e.target.value)}
                                />
                            </div>
                        </div>

                        <div className={"form-group"}>
                            <div className={"submit col-6"} onClick={() => {
                                setStep('step1');
                            }}><a href="javascript:void(0);">上一步</a></div>
                            <div className={"submit col-6"} onClick={() => {
                                if(!((form.reg || {}).pet_name || '').trim().length > 0){
                                    alert('請輸入寵物姓名');
                                    return;
                                }
                                if(!((form.reg || {}).pet_gender || '').trim().length > 0){
                                    alert('請輸入寵物性別');
                                    return;
                                }
                                // if(!((form.reg || {}).pet_type || '').trim()){
                                //     alert('請選擇寵物種類');
                                //     return;
                                // }
                                setStep('step3');
                            }}><a href="javascript:void(0);">下一步</a></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Step2;