import { PageContext, FormContext } from "../pageContext";
import { useContext, useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ReactModal from "react-modal";
import Checkout from "./checkout";
import { getBaseUrl } from "../utils";

function Processing() {
  const [step, setStep] = useContext(PageContext);
  const [form, setFrom] = useContext(FormContext);
  const [sent, setsent] = useState(false);
  const [processing, setProcesing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showStripeModal, setShowStripeModal] = useState(false);
  const [html, setHtml] = useState("");
  const stripePromise = loadStripe(
    "pk_test_51L52VJEiro91Fz9JBClaqOfwws6bUgMKLH56lulTEmAmK7xgfb0o5Q8z7gBeRHIUKmK3PTQb6vNwcVmTzDQdpsYu005aEJF6yz"
  );
  const [clientSecret, setClientSecret] = useState("");

  const appearance = {
    theme: "stripe",
  };

  const onMessageReceived = (event) => {
    if (event.data && event.data === "success") {
      setShowModal(false);
      setStep("success");
    }
  };

  useEffect(() => {
    // TODO: send api
    setProcesing(true);
    window.addEventListener("message", onMessageReceived);

    // cleanup this component
    return () => {
      window.removeEventListener("message", onMessageReceived);
    };
  }, []);

  useEffect(() => {
    if (processing) {
      setsent(true);
      // setTimeout(() => {
      var opt = {
        method: "POST",
        body: JSON.stringify({
          name: form.reg.name,
          email: form.reg.email,
          tel: form.reg.tel,
          address: form.reg.address,
          password: form.reg.password,
          member_type: form.plan.plan,
          pet_name: form.reg.pet_name,
          pet_gender: form.reg.pet_gender,
          // "pet_specie": form.reg.pet_specie,
          pet_breed: form.reg.pet_breed,
          pet_birthday: form.reg.pet_birthday,
          pet_sterilized: form.reg.pet_sterilized || "0",
          pet_vaccination_needle_number:
            form.reg.pet_vaccination_needle_number || "",
          pet_vaccination_needle_image:
            form.reg.pet_vaccination_needle_image || "",
          pet_dog_licence_exp_date: form.reg.pet_dog_licence_exp_date || "",
          pet_vaccination_record_date:
            form.reg.pet_vaccination_record_date || "",
          pet_dog_licence_number: form.reg.pet_dog_licence_number || "",
          pet_dog_licence_image: form.reg.pet_dog_licence_image || "",
          pet_prescription_food: form.reg.pet_prescription_food || "",
          pet_body_condition: ((form.reg || {}).pet_body_condition || []).join(
            ","
          ),
          pet_prescription_food_remark:
            form.reg.pet_prescription_food_remark || "",
          pet_body_condition_remark: form.reg.pet_body_condition_remark || "",
          pet_birth_paper: form.reg.pet_birth_paper || "",
          // "pet_type": form.reg.pet_type,
          promoter:
            form.reg.promoter ||
            window.location.pathname.replace("/", "").trim(),
          payment: form.plan.payment,
          card_num: form.plan.card_num.replace(/ /g, "").trim() || "",
          // "card_name": form.plan.card_name,
          card_exp_year: (form.plan.card_exp || "").split("/")[1],
          card_exp_month: (form.plan.card_exp || "").split("/")[0],
          card_cvc: form.plan.card_cvc || "",
        }),
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
        // redirect: 'follow'
      };

      fetch(`${getBaseUrl()}/api/web/register-with-promotion`, opt)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            if (result.html !== undefined) {
              // create a new modal to show html content
              setHtml(result.html);
              setShowModal(true);
              return;
            }
            if (result.stripe !== undefined) {
              setClientSecret(result.stripe.clientSecret);
            }
            setStep("success");
            return;
          }
          setStep("step3");
          if (result["errors"]) {
            alert(
              result["message"] +
                (Object.values(result["errors"]).length > 0
                  ? "\n" + Object.values(result["errors"]).flat().join("\n")
                  : "")
            );
          } else {
            alert("您輸入的資料有誤，請重新輸入");
          }

        })
        .catch((error) => {
          console.log("error", error);
        });
      // }, 100);
    }
  }, [processing]);

  return (
    <div class="content">
      <div class="container">
        <div class="div_success">
          <form class="form">
            <div class="icon_ok">
              <img src="images/pet.png" />
            </div>
            <h1>
              處理中
              <br />
              請不要重新整理
            </h1>
          </form>
        </div>
      </div>
      <ReactModal isOpen={showModal} contentLabel="Minimal Modal Example">
        <iframe srcdoc={html} />
        {/* <button
          onClick={() => {
            setShowModal(false);
            setStep("success");
          }}
        >
          Press here after complete
        </button> */}
      </ReactModal>

      <ReactModal isOpen={showStripeModal} contentLabel="Minimal Modal Example">
        {clientSecret && (
          <Elements
            options={{
              clientSecret,
              appearance,
            }}
            stripe={stripePromise}
          >
            <Checkout />
          </Elements>
        )}
      </ReactModal>
    </div>
  );
}

export default Processing;
