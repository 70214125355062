import logo from './logo.svg';
import MiniRoute from './miniRoute';
import './App.css';
import { useState } from "react";
import { PageContext, FormContext } from './pageContext';

function App() {
  const _initStep = () => {
    // let obj = localStorage.getItem('step');
    // if(obj){
    //   if(obj == 'success'){
    //     localStorage.setItem('form', "{}");
    //     return 'step1';
    //   }
    //   return obj;
    // }
    return 'step1';
  }
  const _initForm = () => {
    // let obj = localStorage.getItem('form');
    // if(obj){
    //   return JSON.parse(obj);
    // }
    return {};
  }
  const [step, _setStep] = useState(_initStep());
  const [form, _setFrom] = useState(_initForm());

  const setStep = (obj) => {
    _setStep(obj);
    localStorage.setItem('step', obj);

  }

  const setFrom = (obj) => {
    _setFrom(obj);
    let newObj = JSON.parse(JSON.stringify(obj));
    if((newObj.reg ?? {}).pet_birth_paper){
      delete newObj.reg.pet_birth_paper;
    }
    localStorage.setItem('form', JSON.stringify(newObj));
  }


  const clientSecret = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
  );

  if(clientSecret){
    setStep('processing');
  }

  return (
    <div className="App">
      <FormContext.Provider value={[form, setFrom]}>
        <PageContext.Provider value={[step, setStep]}>
          <MiniRoute />
        </PageContext.Provider>
      </FormContext.Provider>
    </div>
  );
}

export default App;
