import { PageContext, FormContext } from '../pageContext';
import { useContext } from "react";

function Success() {
    const [step, setStep] = useContext(PageContext);
    // const [form, setFrom] = useContext(FormContext);
    return (
        <div class="content">
            <div class="container">
                <div class="div_success">
                    <form class="form">
                        <div class="icon_ok"><img src="images/pet.png" /></div>
                        <h1>註冊成功</h1>
                        <p class="success">
                            立即下載Pet Club App<br />
                            <a
                                href="https://apps.apple.com/hk/app/pet-club/id1491003360?l=en"
                                target="_blank">
                                <img src="images/google.png" />
                            </a>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Success;